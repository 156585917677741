import React, { Component } from 'react'
import logo from './logo.svg';
import backgroundMovie from './backgroundMovie.mp4';
import './App.css';
import { render } from '@testing-library/react';
import { Timeline } from 'react-twitter-widgets'
class App extends Component {
  render() {
    return (

      <div className="App">
        <header className="App-header">
          <video src={backgroundMovie} className="App-video" width="750" height="500" type="video/mp4" autoPlay muted loop />
          <div class="App-content">
            <div className="App-search">
              <form action="/action_page.php">
                <input type="text" placeholder="one keyword or name" name="search" />
                <button type="submit">search</button>
              </form>
            </div>

            <p>See previous 280search results below</p>

            <div className="App-timeline">            
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: '280search'
              }}
              options={{
                height: '400',
                theme: 'dark'
              }}
            />
            </div>


          </div>


        </header>
      </div>
    );
  }
}

export default App;
